import React, { useState, useMemo } from "react";
import "./App.css";
import {
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Typography,
  Menu,
  Box,
  Slide,
  Paper,
  Chip,
} from "@mui/material";
import {
  BubbleChart,
  Hub,
  MenuBook,
  NoiseAware,
  KeyboardArrowDown,
  Check,
} from "@mui/icons-material";
import NeighborhoodsVisualization from "./NeighborhoodsVisualization";
import SquishedBubbles from "./SquishedVisualization";

import config from "./config";
import { processHighlights } from "./utils";

const highlights = require(`${config.highlightsJson}`);

function App() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedVisualization, setSelectedVisualization] = useState("");
  const [selectedNode, setSelectedNode] = useState(null);
  const [showArrow, setShowArrow] = useState(false);
  const [showNodeInfo, setShowNodeInfo] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);

  const processedHighlights = useMemo(
    () =>
      processHighlights(
        highlights,
        config.combineConversations,
        config.tagsField
      ),
    []
  );

  const {
    conversationIds,
    conversationToHighlights,
    tagsToHighlights,
    parentTagsToHighlights,
  } = processedHighlights;

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (visualization) => {
    setSelectedVisualization(visualization);
    setAnchorEl(null);
    setSelectedNode(null);
    setShowArrow(false);
  };

  const handleNodeClick = (node) => {
    setSelectedNode(node);
    setShowArrow(node !== null);

    if (node === null) {
      setShowNodeInfo(false);
      setSelectedTag(null);
    }
  };

  const toggleNodeInfo = () => {
    setShowNodeInfo((prev) => !prev);
  };

  const renderVisualization = () => {
    switch (selectedVisualization) {
      case "NeighborhoodsVisualization":
      case "NetworkBundles":
        return (
          <NeighborhoodsVisualization
            conversationIds={conversationIds}
            conversationToHighlights={conversationToHighlights}
            tagsToHighlights={tagsToHighlights}
            parentTagsToHighlights={parentTagsToHighlights}
            highlights={highlights}
            onNodeClick={handleNodeClick}
            networkMode={selectedVisualization === "NetworkBundles"}
          />
        );
      case "SquishedBubbles":
        return (
          <SquishedBubbles
            conversationIds={conversationIds}
            conversationToHighlights={conversationToHighlights}
            highlights={highlights}
            onNodeClick={handleNodeClick}
            autoconnectHighlights={config.autoconnectHighlights}
            conversationNames={config.conversationNames}
            selectedTag={selectedTag}
            showConversationLabels={config.showConversationLabels}
          />
        );
      default:
        return <h1>Select a visualization to view.</h1>;
    }
  };

  return (
    <div className="fullScreen">
      <AppBar position="static" sx={{ backgroundColor: "black" }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            🍭 SenseMap
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuClick}
          >
            <MenuBook />
            <Box sx={{ marginLeft: 1 }} />
            <Typography variant="button">
              {selectedVisualization === ""
                ? " Select Visualization"
                : " Change Visualization"}
            </Typography>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={() => handleMenuClose("NetworkBundles")}>
              <Hub /> <Box sx={{ marginLeft: 1 }} />
              Networks
            </MenuItem>
            <MenuItem
              onClick={() => handleMenuClose("NeighborhoodsVisualization")}
            >
              <NoiseAware /> <Box sx={{ marginLeft: 1 }} />
              Cells
            </MenuItem>
            <MenuItem onClick={() => handleMenuClose("SquishedBubbles")}>
              <BubbleChart /> <Box sx={{ marginLeft: 1 }} />
              Bubbles
            </MenuItem>
          </Menu>
          {showArrow && (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="arrow"
              onClick={toggleNodeInfo}
              className="pulsingArrow"
              sx={{
                position: "absolute",
                right: "50%",
                transform: "translateX(50%)",
              }}
            >
              {showNodeInfo ? (
                <KeyboardArrowDown
                  sx={{ fontSize: 40, transform: "rotate(180deg)" }}
                />
              ) : (
                <KeyboardArrowDown sx={{ fontSize: 40 }} />
              )}
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <div className="visualizationContainer">{renderVisualization()}</div>
      <Slide direction="down" in={showNodeInfo} mountOnEnter unmountOnExit>
        <Paper
          sx={{
            position: "absolute",
            top: 64, // AppBar height
            left: 0,
            right: 0,
            zIndex: 1200, // Ensure it appears above other content
            padding: 2,
            backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent black
            borderRadius: 0,
          }}
        >
          {selectedNode && (
            <>
              <iframe
                title={`Conversation Embed ${selectedNode.id}`}
                src={`https://embed.lvn.org/?hid=${selectedNode.id}`}
                width="100%"
                height="225"
                frameBorder="no"
              ></iframe>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  marginTop: 2,
                }}
              >
                {selectedNode.tags.sort().map((tag) => (
                  <Chip
                    key={tag}
                    icon={tag === selectedTag ? <Check /> : null}
                    label={tag}
                    onClick={() => setSelectedTag(tag)}
                    sx={{
                      backgroundColor:
                        tag === selectedTag
                          ? "rgba(255, 255, 255, 0.8)"
                          : "rgba(255, 255, 255, 0.5)",
                      margin: 0.5,
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                      },
                      opacity: selectedTag && tag !== selectedTag ? 0.5 : 1,
                    }}
                  />
                ))}
              </Box>
            </>
          )}
        </Paper>
      </Slide>
    </div>
  );
}

export default App;
