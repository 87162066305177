export function processHighlights(
  highlights,
  combineConversations,
  tagsField = "tags"
) {
  const conversationIds = [];
  const conversationToHighlights = {};
  const tagsToHighlights = {};
  const parentTagsToHighlights = {};

  highlights.forEach((highlight, index) => {
    const { conversation_id } = highlight;
    const tags = highlight[tagsField] || [];

    // save the tags in the highlight object
    highlight.tags = tags;

    // Process conversationIds
    if (!conversationIds.includes(conversation_id)) {
      conversationIds.push(conversation_id);
    }

    // Process conversationToHighlights
    if (!conversationToHighlights[conversation_id]) {
      conversationToHighlights[conversation_id] = [];
    }
    conversationToHighlights[conversation_id].push(highlight);

    // Process tagsToHighlights and parentTagsToHighlights
    tags.forEach((tag) => {
      // tagsToHighlights
      if (!tagsToHighlights[tag]) {
        tagsToHighlights[tag] = [];
      }
      tagsToHighlights[tag].push(index);

      // parentTagsToHighlights
      const parentTag = tag.split(" :: ")[0];
      if (!parentTagsToHighlights[parentTag]) {
        parentTagsToHighlights[parentTag] = [];
      }
      parentTagsToHighlights[parentTag].push(index);
    });
  });

  if (combineConversations) {
    console.log("NOT IMPLEMENTED - Combining conversations");
  }

  return {
    conversationIds,
    conversationToHighlights,
    tagsToHighlights,
    parentTagsToHighlights,
  };
}
