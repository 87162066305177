import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const NetworkBundles = ({
  conversationIds,
  conversationToHighlights,
  tagsToHighlights,
  parentTagsToHighlights,
  highlights,
  onNodeClick,
  networkMode = false,
}) => {
  const svgRef = useRef(null);

  useEffect(() => {
    const numNetworks = conversationIds.length;
    const rowSize = Math.ceil(Math.sqrt(numNetworks));
    const columnSize = Math.ceil(numNetworks / rowSize);
    const buffer = 20; // Buffer space between networks
    const svg = d3.select(svgRef.current);

    const svgWidth = svg.node().parentNode.clientWidth;
    const svgHeight = svg.node().parentNode.clientHeight;
    svg.attr("width", svgWidth).attr("height", svgHeight);
    svg.style("background-color", "black");

    const networkWidth = svgWidth / columnSize - buffer;
    const networkHeight = svgHeight / rowSize - buffer;

    const allNodes = [];
    const allLinks = [];

    const createNetwork = (row, col, label, conversationId) => {
      const numNodes = conversationToHighlights[conversationId].length;
      const nodes = d3.range(numNodes).map((d) => ({
        id: `${row}-${col}-${d}-network-${Math.random()}`,
        index: d,
        network: row * columnSize + col,
        row,
        col,
        highlight: conversationToHighlights[conversationId][d],
      }));
      const links = d3
        .range(numNodes - 1)
        .map((d) => ({ source: d, target: d + 1 }));

      allNodes.push(...nodes);
      allLinks.push(...links);

      const g = svg
        .append("g")
        .attr(
          "transform",
          `translate(${col * (networkWidth + buffer)}, ${
            row * (networkHeight + buffer)
          })`
        );

      const simulation = d3
        .forceSimulation(nodes)
        .force("charge", d3.forceManyBody().strength(-15))
        .force("collide", d3.forceCollide(10))
        .force("x", d3.forceX(networkWidth / 2).strength(0.9))
        .force("y", d3.forceY(networkHeight / 2).strength(0.9))
        .force(
          "link",
          d3.forceLink(links).id((d) => d.index)
        )
        .force("center", d3.forceCenter(networkWidth / 2, networkHeight / 2));

      const link = networkMode
        ? g
            .selectAll(".link")
            .data(links)
            .join("path")
            .attr("class", "link")
            .attr("stroke-width", 1)
            .attr("stroke-opacity", 0.4)
            .attr("stroke", "white")
            .attr("fill", "none")
        : null;

      const linkPathGenerator = networkMode
        ? d3
            .linkHorizontal()
            .x((d) => d.x)
            .y((d) => d.y)
        : null;

      const node = g
        .selectAll(".node")
        .data(nodes)
        .join("circle")
        .attr("class", "node")
        .attr("r", 5)
        .attr("fill", (d) => (d.highlight ? d.highlight.color : "white"))
        .on("click", (event, d) =>
          handleNodeClick(
            d,
            nodes,
            g,
            networkWidth,
            networkHeight,
            allNodes,
            svg,
            buffer
          )
        );

      simulation.on("tick", () => {
        link?.attr("d", (d) =>
          linkPathGenerator({
            source: d.source,
            target: d.target,
          })
        );

        node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);

        // Draw network outline and label on tick
        if (!networkMode) {
          drawNetworkOutline(g, nodes, label);
        }
      });

      return () => {
        simulation.stop();
        g.remove();
      };
    };

    const cleanups = [];
    for (let i = 0; i < numNetworks; i++) {
      const conversationId = conversationIds[i];
      const row = Math.floor(i / columnSize);
      const col = i % columnSize;
      const label = `Conversation ${conversationId}`;
      cleanups.push(createNetwork(row, col, label, conversationId));
    }

    return () => {
      cleanups.forEach((cleanup) => cleanup());
    };
  }, [conversationIds, networkMode]);

  const handleNodeClick = (
    clickedNode,
    nodes,
    g,
    networkWidth,
    networkHeight,
    allNodes,
    svg,
    buffer
  ) => {
    // Clear existing connections
    svg.selectAll(".new-link").remove();

    onNodeClick(clickedNode.highlight);

    const clickedNodeTags = new Set(clickedNode.highlight.tags);
    console.log(clickedNodeTags);
    const targetNodes = [];
    allNodes.forEach((n) => {
      if (n.highlight) {
        n.highlight.tags.forEach((tag) => {
          if (clickedNodeTags.has(tag)) {
            targetNodes.push(n);
            return;
          }
        });
      }
    });

    // Create a set of connected nodes
    const connectedNodes = new Set(targetNodes.map((node) => node.id));
    connectedNodes.add(clickedNode.id);

    targetNodes.forEach((targetNode) => {
      const linkPathGenerator = d3
        .linkHorizontal()
        .x((d) => d.x + d.col * (networkWidth + buffer))
        .y((d) => d.y + d.row * (networkHeight + buffer));

      const path = svg
        .append("path")
        .datum({ source: clickedNode, target: targetNode })
        .attr("class", "new-link")
        .attr("stroke-width", 1)
        .attr("stroke-opacity", 0.7)
        .attr("stroke", "white")
        .attr("stroke-dasharray", "5,5")
        .attr("fill", "none")
        .attr("d", (d) =>
          linkPathGenerator({
            source: d.source,
            target: d.target,
          })
        );

      const totalLength = path.node().getTotalLength();

      path
        .attr("stroke-dasharray", totalLength + " " + totalLength)
        .attr("stroke-dashoffset", totalLength)
        .transition()
        .duration(1000)
        .ease(d3.easeLinear)
        .attr("stroke-dashoffset", 0);
    });

    // Adjust the opacity of nodes
    svg
      .selectAll(".node")
      .transition()
      .duration(500)
      .attr("opacity", (d) => (connectedNodes.has(d.id) ? 1 : 0.2))
      .attr("r", (d) => (connectedNodes.has(d.id) ? 10 : 5));
  };

  const drawNetworkOutline = (g, nodes, label) => {
    // Remove existing outlines and labels
    g.selectAll(".network-outline").remove();
    g.selectAll(".network-label").remove();

    if (nodes.length < 3) {
      // Handle cases with fewer than three nodes by drawing a circle around them
      const centroid = nodes.reduce(
        (acc, node) => [
          acc[0] + node.x / nodes.length,
          acc[1] + node.y / nodes.length,
        ],
        [0, 0]
      );

      const radius = nodes.reduce((acc, node) => {
        const distance = Math.sqrt(
          Math.pow(node.x - centroid[0], 2) + Math.pow(node.y - centroid[1], 2)
        );
        return Math.max(acc, distance);
      }, 20); // Minimum radius

      g.append("circle")
        .attr("class", "network-outline")
        .attr("cx", centroid[0])
        .attr("cy", centroid[1])
        .attr("r", radius + 5) // Adding a buffer to the radius
        .attr("stroke", "white")
        .attr("stroke-opacity", 0.7)
        .attr("stroke-width", 2)
        .attr("fill", "none")
        .attr("stroke-dasharray", "10,5");

      // Add the label at the centroid
      g.append("text")
        .attr("class", "network-label")
        .attr("x", centroid[0])
        .attr("y", centroid[1])
        .attr("text-anchor", "middle")
        .attr("fill", "white")
        .attr("font-size", "12px")
        .attr("opacity", 0.7)
        .text(label);
    } else {
      // Calculate the convex hull of the nodes
      const hull = d3.polygonHull(nodes.map((d) => [d.x, d.y]));

      if (hull) {
        // Calculate the centroid of the hull
        const centroid = d3.polygonCentroid(hull);
        const expansionFactor = 20; // Amount by which to expand the hull

        // Expand the convex hull outward from the centroid
        const expandedHull = hull.map(([x, y]) => {
          const [cx, cy] = centroid;
          const dx = x - cx;
          const dy = y - cy;
          const dist = Math.sqrt(dx * dx + dy * dy);
          const scale = (dist + expansionFactor) / dist;
          return [cx + dx * scale, cy + dy * scale];
        });

        // Create a smooth closed curve around the expanded convex hull
        const outlinePathGenerator = d3
          .line()
          .x((d) => d[0])
          .y((d) => d[1])
          .curve(d3.curveBasisClosed);

        g.append("path")
          .datum(expandedHull)
          .attr("class", "network-outline")
          .attr("d", outlinePathGenerator)
          .attr("stroke", "white")
          .attr("stroke-opacity", 0.7)
          .attr("stroke-width", 2)
          .attr("fill", "none")
          .attr("stroke-dasharray", "10,5");

        // Add the label at the centroid of the expanded hull
        g.append("text")
          .attr("class", "network-label")
          .attr("x", centroid[0])
          .attr("y", centroid[1])
          .attr("text-anchor", "middle")
          .attr("fill", "white")
          .attr("font-size", "12px")
          .attr("opacity", 0.7)
          .text(label);
      }
    }
  };

  return <svg ref={svgRef}></svg>;
};

export default NetworkBundles;
