// may 7th highlights
const may7thConfig = {
  highlightsJson: "./data/may-7-highlights.json",
  autoconnectHighlights: [
    5282637, 5282661, 5282457, 5282282, 5282646, 5282824, 5282639, 5282591,
    5282484, 5282671, 5282444, 5282250, 5282832, 5282827,
  ],
  combineConversations: {
    5450: [5450, 5451],
  },
  conversationNames: {
    5408: "🎤 State of the Network",
    5404: "🎤 On Democracy",
    5406: "🎤 On Truth",
    5405: "🎤 On Listening",
    5411: "📝 Media Ecosystems",
    5446: "📝 Tech-Enhanced Citizens\nAssemblies",
    5447: "📝 Democracy & AI",
    5448: "📝 Workplaces as\nListening Orgs",
    5449: "📝 Dialogue &\nSocial Media",
    5450: "📝 Youth Voices",
  },
  tagsField: "primary_tags",
  showConversationLabels: false,
};

const rtfcConfig = {
  highlightsJson: "./data/rtfc-highlights.json",
  autoconnectHighlights: [], //5242881, 5244525, 3166734, 4112246, 5244581],
};

const config = rtfcConfig;

export default config;
